import { createContext, useContext, useState, useEffect } from "react";
import {
  authenticateUser,
  updateLastLogin,
  getClientLocation,
  getUserIP,
} from "../services/Calls";
import { useToast } from "../context/Toast";
import { Navigate } from "react-router-dom";
import { LanguageContext } from "../context/Idiom.js";
import { Timestamp, firestore, doc, getDoc } from "../services/Connection.js";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { showCustomToast } = useToast();
  const { activeLanguage } = useContext(LanguageContext);

  const refreshUserData = async () => {
    const storedUser = sessionStorage.getItem("user");
    if (!storedUser) return;

    const localUser = JSON.parse(storedUser);
    const clientUser = localUser.client_user;

    try {
      const db = firestore;
      const userRef = doc(db, "Auth", clientUser);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const updatedUser = {
          ...localUser,
          ...userSnap.data(),
        };

        setUser(updatedUser);
        sessionStorage.setItem("user", JSON.stringify(updatedUser));
      } else {
        console.error("User not found");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));

      refreshUserData();
    }

    setLoading(false);
  }, []);

  async function signInWithEmailAndPassword(email, password) {
    setLoading(true);

    const LastJLogin = Timestamp.now();
    const LastLocation = await getClientLocation();
    const LastIp = await getUserIP();

    const errorMessages = {
      pt: {
        101: "Usuário não encontrado!",
        103: "Senha incorreta!",
        105: "Erro ao autenticar, tente novamente!",
        default: "Erro na autenticação, tente mais tarde!",
      },
      en: {
        101: "User not found!",
        103: "Incorrect password!",
        105: "Authentication error, please try again!",
        default: "Authentication failed, please try later!",
      },
      fr: {
        101: "Utilisateur non trouvé!",
        103: "Mot de passe incorrect!",
        105: "Erreur d'authentification, veuillez réessayer!",
        default: "Échec de l'authentification, veuillez réessayer plus tard!",
      },
    };

    try {
      const authenticatedUser = await authenticateUser(
        email,
        password,
        showCustomToast
      );
      setUser(authenticatedUser);
      sessionStorage.setItem("user", JSON.stringify(authenticatedUser));
      updateLastLogin(email, LastJLogin, LastLocation, LastIp);
    } catch (error) {
      const errorMessage =
        errorMessages[activeLanguage]?.[error.message] ||
        errorMessages[activeLanguage]?.default ||
        "Unknown error";
      showCustomToast(errorMessage, "error");
      console.error("Authentication error:", error);
    } finally {
      setLoading(false);
    }
  }

  function updateUser(updatedUser) {
    setUser(updatedUser);
    sessionStorage.setItem("user", JSON.stringify(updatedUser));
  }

  function signOut() {
    sessionStorage.clear();
    setUser(null);
    return <Navigate to="/" />;
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        signInWithEmailAndPassword,
        updateUser,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
