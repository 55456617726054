export const languages = {
  en: {
    nickname: "Nickname",
    clientId: "Identifier",
    account: "Account",
    situation: "Status",
    title: "Title",
    rule: {
      client: "Client",
      adm: "Administrator",
      president: "President",
      manager: "Manager",
      stockholder: "Shareholder",
    },
    plans: {
      free: "Free Plan",
      tools: "Tools Plan",
      healthy: "Healthy Plan",
      premium: "Premium Plan",
    },
    status: {
      blocked: "Blocked",
      inactive: "Inactive",
      active: "Active",
    },
  },
  fr: {
    nickname: "Surnom",
    clientId: "Identifiant",
    account: "Compte",
    situation: "Statut",
    title: "Titre",
    rule: {
      client: "Client",
      adm: "Administrateur",
      president: "Président",
      manager: "Gestionnaire",
      stockholder: "Actionnaire",
    },
    plans: {
      free: "Plan gratuit",
      tools: "Plan outils",
      healthy: "Plan santé",
      premium: "Plan premium",
    },
    status: {
      blocked: "Bloqué",
      inactive: "Inactif",
      active: "Actif",
    },
  },
  pt: {
    nickname: "Apelido",
    clientId: "Identificador",
    account: "Conta",
    situation: "Status",
    title: "Título",
    rule: {
      client: "Cliente",
      adm: "Administrador",
      president: "Presidente",
      manager: "Gerente",
      stockholder: "Acionista",
    },
    plans: {
      free: "Plano gratuito",
      tools: "Plano ferramentas",
      healthy: "Plano saúde",
      premium: "Plano premium",
    },
    status: {
      blocked: "Bloqueado",
      inactive: "Inativo",
      active: "Ativo",
    },
  },
};
