import { React, useState, useEffect, useRef, useContext } from "react";
import routes from "../../../routes/Path.js";
import { getClientLocation, getUserIP } from "../../../services/Calls";
import createClient from "../../../services/controller/CreateClient";
import { profileIcons } from "../../../imports/profile";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../context/Toast";
import { LanguageContext } from "../../../context/Idiom.js";
import { sendNotification } from "../../../services/controller/Notify.js";
import {
  notVerified,
  clientInactive,
  amlPending,
  kycPending,
} from "../../../services/model/NotifyModel.js";

import "../styles.css";
import { languages } from "../../../language/register.js";

import { eyeSlash, eye, arrowRightShort } from "../../../imports/icons";

function Register() {
  const bcrypt = require("bcryptjs");
  const saltRounds = 10;

  const { activeLanguage } = useContext(LanguageContext);

  const navigate = useNavigate();
  const nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const indicationInputRef = useRef(null);

  const [indicationInput, setIndicationInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [imageInput, setImageInput] = useState(null);
  const [serviceInput, setServiceInput] = useState(false);
  const [policyInput, setPolicyInput] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { showCustomToast } = useToast();

  const isFormValid =
    nameInput !== "" &&
    emailInput !== "" &&
    passwordInput !== "" &&
    imageInput !== null &&
    serviceInput !== false &&
    policyInput !== false;

  const [indication, setIndication] = useState(false);
  const [name, setName] = useState(false);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [img, setImg] = useState(false);
  const [terms, setTerms] = useState(false);

  const handleIndicationChange = (event) => {
    let inputValue = event.target.value;

    inputValue = inputValue.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

    if (inputValue.length > 2) {
      inputValue = `${inputValue.slice(0, 2)}-${inputValue.slice(2)}`;
    }
    if (inputValue.length > 5) {
      inputValue = `${inputValue.slice(0, 5)}-${inputValue.slice(5)}`;
    }
    if (inputValue.length > 12) {
      inputValue = `${inputValue.slice(0, 12)}-${inputValue.slice(12, 13)}`;
    }
    if (inputValue.length > 13) {
      inputValue = inputValue.slice(0, 14);
    }

    setIndicationInput(inputValue);
  };

  const handleNameChange = (event) => {
    setNameInput(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPasswordInput(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;

    if (id === "service") {
      setServiceInput(checked);
    } else if (id === "policy") {
      setPolicyInput(checked);
    }

    event.target.blur();
  };

  const handleImageClick = (key) => {
    setImageInput(key);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
    if (passwordInputRef.current) {
      passwordInputRef.current.type = showPassword ? "password" : "text";
    }
  };

  const imageGroups = Object.keys(profileIcons).reduce((acc, key, index) => {
    const groupIndex = Math.floor(index / 4);
    if (!acc[groupIndex]) acc[groupIndex] = [];
    acc[groupIndex].push({ key, src: profileIcons[key] });
    return acc;
  }, []);

  const handleStepClick = (step) => {
    setIndication(step === "indication");
    setName(step === "name");
    setEmail(step === "email");
    setPassword(step === "password");
    setImg(step === "img");
    setTerms(step === "terms");

    if (step !== "password") {
      setShowPassword(false);
    }
  };

  const hashPassword = async (password) => {
    try {
      const salt = await bcrypt.genSalt(saltRounds);
      const hashedPassword = await bcrypt.hash(password, salt);
      return hashedPassword;
    } catch (error) {
      console.error("Error generating password hash:", error);
      throw new Error("Failed to generate password hash");
    }
  };

  const handleUserSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (passwordInput.length > 0 && passwordInput.length < 6) {
      showCustomToast(languages[activeLanguage].passwordLengthError, "alert");
      setLoading(false);
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(emailInput)) {
      showCustomToast(languages[activeLanguage].invalidEmail, "alert");
      setLoading(false);
      return;
    }

    if (nameInput.length < 3) {
      showCustomToast(languages[activeLanguage].nameLengthError, "alert");
      setLoading(false);
      return;
    } else if (nameInput.split(" ").length < 2) {
      showCustomToast(languages[activeLanguage].enterFullName, "alert");
      setLoading(false);
      return;
    }

    try {
      const hashedPassword = await hashPassword(passwordInput);
      const creationDate = Timestamp.now();
      const clientLocation = await getClientLocation();
      const clientIp = await getUserIP();

      const user = {
        permission: "free",
        name: nameInput,
        password: hashedPassword,
        imgId: imageInput,
        clientIp: clientIp,
        indication: indicationInput,
        email: emailInput,
        rule: "user",
        status: "inactive",
        creationTime: creationDate,
        updatedTime: null,
        phoneNumber: null,
        associateDate: indicationInput ? creationDate : null,
        location: clientLocation,
        language: activeLanguage || "en",
      };

      const clientCode = await createClient(user);
      showCustomToast(languages[activeLanguage].accountCreated, "success");

      sendNotification(notVerified, clientCode);
      sendNotification(clientInactive, clientCode);
      sendNotification(amlPending, clientCode);
      sendNotification(kycPending, clientCode);

      navigate(routes.login);
    } catch (err) {
      console.error("Error creating client in Firestore:", err);
      showCustomToast(languages[activeLanguage].registrationError, "error");
      setLoading(false);
    } finally {
      setLoading(false);
      setNameInput("");
      setEmailInput("");
      setPasswordInput("");
      setImageInput(null);
      setServiceInput(false);
      setPolicyInput(false);
      setIndication(false);
      setName(false);
      setEmail(false);
      setPassword(false);
      setImg(false);
      setTerms(false);
    }
  };

  useEffect(() => {
    if (indication) {
      if (indicationInputRef.current) {
        indicationInputRef.current.focus();
      }
    }
  }, [indication]);

  useEffect(() => {
    if (name) {
      if (nameInputRef.current) {
        nameInputRef.current.focus();
      }
    }
  }, [name]);

  useEffect(() => {
    if (email) {
      if (emailInputRef.current) {
        emailInputRef.current.focus();
      }
    }
  }, [email]);

  useEffect(() => {
    if (password) {
      if (passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
    }
  }, [password]);

  const captureUrlData = () => {
    const url = window.location.href;
    const match = url.match(/\/register[/?](.+)/);

    if (match) {
      let urlData = match[1];
      urlData = urlData.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

      if (urlData.length > 2) {
        urlData = `${urlData.slice(0, 2)}-${urlData.slice(2)}`;
      }
      if (urlData.length > 5) {
        urlData = `${urlData.slice(0, 5)}-${urlData.slice(5)}`;
      }
      if (urlData.length > 12) {
        urlData = `${urlData.slice(0, 12)}-${urlData.slice(12, 13)}`;
      }
      if (urlData.length > 13) {
        urlData = urlData.slice(0, 14);
      }

      setIndicationInput(urlData);
    }
  };

  useEffect(() => {
    setIndication(true);
    captureUrlData();

    document.querySelectorAll(".form-check-input").forEach((checkbox) => {
      checkbox.addEventListener("click", (event) => {
        setTimeout(() => {
          event.target.focus();
        }, 10);
      });
    });
  }, []);

  return (
    <>
      <section className="ct-access">
        <div className="access">
          <form>
            <h1>
              {languages[activeLanguage].welcome}{" "}
              <span className="brand" onClick={() => navigate(routes.home)}>
                <strong>3ÄDY</strong>
              </span>
            </h1>

            {indication && (
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  ref={indicationInputRef}
                  value={indicationInput}
                  onChange={handleIndicationChange}
                />
                <label for="floatingInput">
                  {languages[activeLanguage].referredBySomeone}
                </label>
              </div>
            )}
            {name && (
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="floatingInput"
                  ref={nameInputRef}
                  value={nameInput}
                  onChange={handleNameChange}
                />
                <label for="floatingInput">
                  {languages[activeLanguage].fullName}
                </label>
              </div>
            )}
            {email && (
              <div class="form-floating mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="floatingInput"
                  ref={emailInputRef}
                  value={emailInput}
                  onChange={handleEmailChange}
                />
                <label for="floatingInput">
                  {languages[activeLanguage].emailAddress}
                </label>
              </div>
            )}
            {password && (
              <div class="form-floating">
                <input
                  type="password"
                  class="form-control"
                  id="floatingPassword"
                  ref={passwordInputRef}
                  value={passwordInput}
                  onChange={handlePasswordChange}
                />
                <label for="floatingPassword">
                  {languages[activeLanguage].yourPassword}
                </label>
                <span className="eye" onClick={togglePasswordVisibility}>
                  <img
                    loading="lazy"
                    alt="eye"
                    src={showPassword ? eyeSlash : eye}
                  />
                </span>
              </div>
            )}
            {img && (
              <div id="carouselExampleCaptions" className="carousel slide">
                <div className="carousel-inner">
                  {imageGroups.map((group, index) => (
                    <div
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                      key={index}
                    >
                      <div className="ct-img">
                        {group.map((item) => (
                          <img
                            key={item.key}
                            src={item.src}
                            alt={`Icone ${item.key}`}
                            onClick={() => handleImageClick(item.key)}
                            className={`image-item ${
                              imageInput === item.key ? "selected" : ""
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            )}
            {terms && (
              <div class="ct-terms">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="service"
                    checked={serviceInput}
                    onChange={handleCheckboxChange}
                  />
                  <label class="form-check-label" for="service">
                    {languages[activeLanguage].agreeTerms}
                  </label>
                </div>
                <div class="form-check form-switch second-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="policy"
                    checked={policyInput}
                    onChange={handleCheckboxChange}
                  />
                  <label class="form-check-label" for="policy">
                    {languages[activeLanguage].agreePrivacy}
                  </label>
                </div>
              </div>
            )}
            <div className="login-indication">
              <div className={`step-btn ${indication ? "active-btn" : ""}`}>
                <p onClick={() => handleStepClick("indication")}>01</p>
              </div>
              <div>
                <img loading="lazy" alt="success" src={arrowRightShort} />
              </div>
              <div className={`step-btn ${name ? "active-btn" : ""}`}>
                <p onClick={() => handleStepClick("name")}>02</p>
              </div>
              <div>
                <img loading="lazy" alt="success" src={arrowRightShort} />
              </div>
              <div className={`step-btn ${email ? "active-btn" : ""}`}>
                <p onClick={() => handleStepClick("email")}>03</p>
              </div>
              <div>
                <img loading="lazy" alt="success" src={arrowRightShort} />
              </div>
              <div className={`step-btn ${password ? "active-btn" : ""}`}>
                <p onClick={() => handleStepClick("password")}>04</p>
              </div>
              <div>
                <img loading="lazy" alt="success" src={arrowRightShort} />
              </div>
              <div className={`step-btn ${img ? "active-btn" : ""}`}>
                <p onClick={() => handleStepClick("img")}>05</p>
              </div>
              <div>
                <img loading="lazy" alt="success" src={arrowRightShort} />
              </div>
              <div className={`step-btn ${terms ? "active-btn" : ""}`}>
                <p onClick={() => handleStepClick("terms")}>06</p>
              </div>
            </div>
            <div>
              {loading ? (
                <button
                  type="submit"
                  className="btn btn-outline-dark disable"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  {languages[activeLanguage].pleaseWait}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-dark"
                  onClick={handleUserSignUp}
                  disabled={!isFormValid}
                >
                  {languages[activeLanguage].createAccount}
                </button>
              )}
            </div>
            <p className="label-links terms">
              {languages[activeLanguage].alreadyMember}{" "}
              <span className="links" onClick={() => navigate(routes.login)}>
                {languages[activeLanguage].access}
              </span>
              {". "}
              {languages[activeLanguage].termsImportant}{" "}
              <span className="links">
                {languages[activeLanguage].termsService}
              </span>{" "}
              {languages[activeLanguage].termsOur}{" "}
              <span className="links">
                {languages[activeLanguage].termsPrivacy}
              </span>
            </p>
          </form>
        </div>
      </section>
    </>
  );
}

export default Register;
