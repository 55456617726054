import AppRoutes from "./routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/Auth";
import { ToastProvider } from "./context/Toast";
import { LoadProvider } from "./context/Load";
import { LanguageProvider } from "./context/Idiom";

function App() {
  return (
    <LanguageProvider>
      <LoadProvider>
        <ToastProvider>
          <AuthProvider>
            <Router>
              <AppRoutes />
            </Router>
          </AuthProvider>
        </ToastProvider>
      </LoadProvider>
    </LanguageProvider>
  );
}

export default App;
