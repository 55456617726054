import React, { useEffect, useContext, useState } from "react";
import { useAuth } from "../../../../context/Auth";
import Navigation from "../../../../components/Navigation";
import Container from "../../../../components/Container";
import { verified } from "../../../../imports/icons";
import { LanguageContext } from "../../../../context/Idiom.js";

import "./styles.css";
import "../../../../spaces.css";
import { languages } from "../../../../language/profile.js";
import {
  pencil,
  eyeSlash,
  eye,
  chevronRightFill,
  caretDown,
} from "../../../../imports/icons";

function Profile() {
  const { user } = useAuth();
  const { activeLanguage } = useContext(LanguageContext);
  const [isHidden, setIsHidden] = useState(true);
  const [hasWallet, setHasWallet] = useState();
  const [expandedCards, setExpandedCards] = useState([]);

  const toggleCard = (cardName) => {
    setExpandedCards((prev) =>
      prev.includes(cardName)
        ? prev.filter((c) => c !== cardName)
        : [...prev, cardName]
    );
  };

  const getPlanName = () => {
    const permission = user.client_access.client_permission;
    return (
      languages[activeLanguage].plans[permission] ||
      languages[activeLanguage].plans.free
    );
  };

  const getTitleClient = () => {
    const rule = user.client_access.client_rule;
    return (
      languages[activeLanguage].rule[rule] ||
      languages[activeLanguage].rule.client
    );
  };

  const handleChangeCoin = () => {};

  const handleSeeValue = () => {
    setIsHidden((prev) => !prev);
  };

  const getStatus = () => {
    switch (user.client_access.client_status) {
      case "blocked":
        return {
          text: languages[activeLanguage].status.blocked,
          dotClass: "dot-red",
        };
      case "inactive":
        return {
          text: languages[activeLanguage].status.inactive,
          dotClass: "dot-yellow",
        };
      case "active":
        return {
          text: languages[activeLanguage].status.active,
          dotClass: "dot-green",
        };
      default:
        return {
          text: languages[activeLanguage].status.inactive,
          dotClass: "dot-yellow",
        };
    }
  };

  const { text, dotClass } = getStatus();

  useEffect(() => {
    // handleInitialData();
    setHasWallet("0x0c35c6D7c7A21f3299285840710437C50f608B19");
  }, []); //eslint-disable-line

  return (
    <div>
      <Navigation />
      <Container>
        <div>
          <div className="ct-info">
            <div className="profile-info">
              <div className="margin-right-28">
                <div className="title-label">
                  {languages[activeLanguage].nickname}
                </div>
                <div className="client-name">
                  <strong>
                    <h1>{user.client_nickname}</h1>
                  </strong>
                  <div className="verified">
                    <img loading="lazy" alt="eye" src={verified} />
                  </div>
                </div>
              </div>

              <div className="divider-profile margin-right-28"></div>

              <div className="margin-right-48">
                <div className="title-label">
                  {languages[activeLanguage].clientId}
                </div>
                <div>{user.client_code}</div>
              </div>

              <div className="margin-right-48 hide-mobile">
                <div className="title-label">
                  {languages[activeLanguage].account}
                </div>
                <div>{getPlanName()}</div>
              </div>

              <div className="margin-right-48 hide-mobile">
                <div className="title-label">
                  {languages[activeLanguage].situation}
                </div>
                <div className="status-profile">
                  <div className={`dot ${dotClass}`}></div>
                  {text}
                </div>
              </div>

              <div className="hide-mobile">
                <div className="title-label">
                  {languages[activeLanguage].title}
                </div>
                <div>{getTitleClient()}</div>
              </div>
            </div>
            <div className="edit-profile" type="button">
              <img loading="lazy" alt="profile" src={pencil} />
            </div>
          </div>
          <div className="profile-datail">
            <div>
              <div className="title-label">
                {languages[activeLanguage].account}
              </div>
              <div>{getPlanName()}</div>
            </div>

            <div>
              <div className="title-label">
                {languages[activeLanguage].situation}
              </div>
              <div className="status-profile">
                <span className={`dot ${dotClass}`}></span>
                {text}
              </div>
            </div>

            <div>
              <div className="title-label">
                {languages[activeLanguage].title}
              </div>
              <div>{getTitleClient()}</div>
            </div>
          </div>

          {/* Card: Carteira da blokchain */}
          <div className="box-profile wallet-hash padding-24">
            <p className="label-wallet">Carteira ¥AD:</p>
            <p className="truncat-wallet">
              {hasWallet
                ? isHidden
                  ? "************************"
                  : hasWallet
                : "Cadastre sua carteira"}
            </p>
          </div>

          {/* Card: Dados de saldo da conta */}
          <div className="box-profile box-profile-bkg padding-24 margin-top-24">
            <div className="value-label">
              <h4>Saldo estimado</h4>
              <img
                className="see-value"
                onClick={handleSeeValue}
                loading="lazy"
                alt="toggle visibility"
                src={isHidden ? eye : eyeSlash}
              />
            </div>
            <div className="value-profile">
              <div className="price">
                <h2>{isHidden ? "****" : "0,00€"}</h2>
                <span nClick={handleChangeCoin} className="small-label">
                  <p>EUR</p>
                  <img
                    className="arrow-coin"
                    loading="lazy"
                    alt="profile"
                    src={chevronRightFill}
                  />
                </span>
              </div>
              <div className="price-yad">
                <h3>¥AD {isHidden ? "****" : "0,00"}</h3>
              </div>
              <div>{isHidden ? "** Unid. | **%" : "0 Unid. | 0%"}</div>
            </div>
          </div>

          {/* Card: Dados do Cliente */}
          <div
            className={`box-profile margin-top-24 ${
              expandedCards.includes("client") ? "box-profile-bkg" : ""
            }`}
          >
            <div
              className="card-header-btn"
              onClick={() => toggleCard("client")}
            >
              <img
                loading="lazy"
                alt="caret"
                src={caretDown}
                className={`caret-icon ${
                  expandedCards.includes("client") ? "rotate-up" : "rotate-down"
                }`}
              />
              <p>Pessoais: Nome, Idade, Indentidade...</p>
            </div>
            {expandedCards.includes("client") && (
              <div className="card-content">
                <p>Conteúdo do Cliente...</p>
              </div>
            )}
          </div>

          {/* Card: Dados de Contato */}
          <div
            className={`box-profile margin-top-24 ${
              expandedCards.includes("contact") ? "box-profile-bkg" : ""
            }`}
          >
            <div
              className="card-header-btn"
              onClick={() => toggleCard("contact")}
            >
              <img
                loading="lazy"
                alt="caret"
                src={caretDown}
                className={`caret-icon ${
                  expandedCards.includes("contact")
                    ? "rotate-up"
                    : "rotate-down"
                }`}
              />
              <p>Contato: E-mail, Telefone e Rede Sociais</p>
            </div>
            {expandedCards.includes("contact") && (
              <div className="card-content">
                <p>Conteúdo de Contato...</p>
              </div>
            )}
          </div>

          {/* Card: Dados de Endereço */}
          <div
            className={`box-profile margin-top-24 ${
              expandedCards.includes("address") ? "box-profile-bkg" : ""
            }`}
          >
            <div
              className="card-header-btn"
              onClick={() => toggleCard("address")}
            >
              <img
                loading="lazy"
                alt="caret"
                src={caretDown}
                className={`caret-icon ${
                  expandedCards.includes("address")
                    ? "rotate-up"
                    : "rotate-down"
                }`}
              />
              <p>Endereço: País, Região, Código postal</p>
            </div>
            {expandedCards.includes("address") && (
              <div className="card-content">
                <p>Conteúdo de Endereço...</p>
              </div>
            )}
          </div>

          {/* Card: Dados de Segurança */}
          <div
            className={`box-profile margin-top-24 ${
              expandedCards.includes("security") ? "box-profile-bkg" : ""
            }`}
          >
            <div
              className="card-header-btn"
              onClick={() => toggleCard("security")}
            >
              <img
                loading="lazy"
                alt="caret"
                src={caretDown}
                className={`caret-icon ${
                  expandedCards.includes("security")
                    ? "rotate-up"
                    : "rotate-down"
                }`}
              />
              <p>Dados de segurança</p>
            </div>
            {expandedCards.includes("security") && (
              <div className="card-content">
                <p>Conteúdo de Segurança...</p>
              </div>
            )}
          </div>

          {/* Card: Dados de Termos */}
          <div
            className={`box-profile margin-top-24 ${
              expandedCards.includes("terms") ? "box-profile-bkg" : ""
            }`}
          >
            <div
              className="card-header-btn"
              onClick={() => toggleCard("terms")}
            >
              <img
                loading="lazy"
                alt="caret"
                src={caretDown}
                className={`caret-icon ${
                  expandedCards.includes("terms") ? "rotate-up" : "rotate-down"
                }`}
              />
              <p>Dados de termos</p>
            </div>
            {expandedCards.includes("terms") && (
              <div className="card-content">
                <p>Conteúdo de Termos...</p>
              </div>
            )}
          </div>

          {/* Card: Dados de Indicações */}
          <div
            className={`box-profile margin-top-24 ${
              expandedCards.includes("referrals") ? "box-profile-bkg" : ""
            }`}
          >
            <div
              className="card-header-btn"
              onClick={() => toggleCard("referrals")}
            >
              <img
                loading="lazy"
                alt="caret"
                src={caretDown}
                className={`caret-icon ${
                  expandedCards.includes("referrals")
                    ? "rotate-up"
                    : "rotate-down"
                }`}
              />
              <p>Dados de indicações</p>
            </div>
            {expandedCards.includes("referrals") && (
              <div className="card-content">
                <p>Conteúdo de Indicações...</p>
              </div>
            )}
          </div>

          {/* Card: Dados de Notificações */}
          <div
            className={`box-profile margin-top-24 ${
              expandedCards.includes("notifications") ? "box-profile-bkg" : ""
            }`}
          >
            <div
              className="card-header-btn"
              onClick={() => toggleCard("notifications")}
            >
              <img
                loading="lazy"
                alt="caret"
                src={caretDown}
                className={`caret-icon ${
                  expandedCards.includes("notifications")
                    ? "rotate-up"
                    : "rotate-down"
                }`}
              />
              <p>Dados de notificações</p>
            </div>
            {expandedCards.includes("notifications") && (
              <div className="card-content">
                <p>Conteúdo de Notificações...</p>
              </div>
            )}
          </div>

          {/* Card: Dados de Exclusão de Conta */}
          <div
            className={`box-profile margin-top-24 ${
              expandedCards.includes("delete") ? "box-profile-bkg" : ""
            }`}
          >
            <div
              className="card-header-btn"
              onClick={() => toggleCard("delete")}
            >
              <img
                loading="lazy"
                alt="caret"
                src={caretDown}
                className={`caret-icon ${
                  expandedCards.includes("delete") ? "rotate-up" : "rotate-down"
                }`}
              />
              <p>Dados de exclusão de conta</p>
            </div>
            {expandedCards.includes("delete") && (
              <div className="card-content">
                <p>Conteúdo de Exclusão de Conta...</p>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Profile;
