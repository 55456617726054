// import jwt from "jsonwebtoken";
import bcrypt from "bcryptjs";
import {
  firestore,
  getDoc,
  doc,
  updateDoc,
} from "./Connection";
import { Timestamp } from "firebase/firestore";

export const getClientLocation = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    return `${data.city}, ${data.region}, ${data.country_name}`;
  } catch (error) {
    console.error("Erro ao obter a localização do cliente", error);
    return "Localização desconhecida";
  }
};

export const getUserIP = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (err) {
    console.error("Erro ao obter o IP do usuário:", err);
    return null;
  }
};

export const getCountryCode = async () => {
  try {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();

    let countryCode = data.country_code || "BR";

    if (countryCode.length > 2) {
      countryCode = countryCode.slice(0, 2);
    }

    if (countryCode.length < 2) {
      countryCode = countryCode + "O";
    }

    return countryCode;
  } catch (error) {
    console.error("Erro ao obter o código do país", error);
    return "BR";
  }
};

export const authenticateUser = async (email, password) => {
  try {
    const userDocRef = doc(firestore, "Auth", email);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      throw new Error("101");
    }

    const userData = userDoc.data();
    const isPasswordValid = await bcrypt.compare(
      password,
      userData.client_password
    );

    if (!isPasswordValid) {
      throw new Error("103");
    }

    return userData;
  } catch (error) {
    throw new Error(error.message || "105");
  }
};

export const getVerificationCode = async (email) => {
  let clientCode = "";
  let codeExists = false;

  while (!codeExists) {
    const docRef = doc(firestore, "Auth", email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const clientVerification = docSnap.data().client_verification;

      if (clientVerification) {
        const { client_number, client_time } = clientVerification;
        const currentTime = Timestamp.now();
        const timeDifference = currentTime.toMillis() - client_time.toMillis();

        if (timeDifference > 3600000) {
          const error = new Error("Code expired");
          error.code = "CODE_EXPIRED";
          throw error;
        }

        clientCode = client_number;
        codeExists = clientCode !== undefined && clientCode !== "";
      } else {
        codeExists = true;
      }
    } else {
      codeExists = true;
    }
  }

  return clientCode;
};

export const createVerificationCode = async (email) => {
  try {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let clientCode = "";
    let clientName = "";

    for (let i = 0; i < 3; i++) {
      clientCode += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    clientCode += "-";
    for (let i = 0; i < 3; i++) {
      clientCode += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    const docRef = doc(firestore, "Auth", email);
    const clientTime = Timestamp.now();

    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      const error = new Error("Email not found");
      error.code = "EMAIL_NOT_FOUND";
      throw error;
    }

    await updateDoc(docRef, {
      client_verification: {
        client_number: clientCode,
        client_time: clientTime,
      },
    });

    clientName = docSnap.data().client_name;

    return { clientCode, clientName };
  } catch (error) {
    throw error;
  }
};

export const updateVerificationCode = async (email) => {
  const docRef = doc(firestore, "Auth", email);
  await updateDoc(docRef, {
    client_verification: {
      client_number: "",
      client_time: null,
    },
  });
};

export const updatePassword = async (email, password, data) => {
  const docRef = doc(firestore, "Auth", email);
  await updateDoc(docRef, {
    client_password: password,
    client_updated: {
      client_2fa: null,
      client_password: data,
      client_user: null,
    },
  });
};

export const updateLastLogin = async (
  email,
  LastJLogin,
  LastLocation,
  LastIp
) => {
  const docRef = doc(firestore, "Auth", email);
  try {
    await updateDoc(docRef, {
      "client_metadata.client_last_login": LastJLogin,
      "client_metadata.client_last_location": LastLocation,
      "client_metadata.client_ip": LastIp,
    });
    console.log("Login details updated successfully!");
  } catch (error) {
    console.error("Error updating login details:", error);
  }
};

export const getClientData = async (clientCode) => {
  const docRef = doc(firestore, "User", clientCode);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};
