import { React, useState, useEffect, useRef, useContext } from "react";
import { useToast } from "../../../context/Toast";
import routes from "../../../routes/Path.js";
import {
  getVerificationCode,
  createVerificationCode,
  updateVerificationCode,
  updatePassword,
} from "../../../services/Calls";
import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../context/Idiom.js";
import emailjs from "emailjs-com";

import "../styles.css";
import { languages } from "../../../language/password.js";

import { eyeSlash, eye } from "../../../imports/icons";

function Password() {
  const bcrypt = require("bcryptjs");
  const saltRounds = 10;

  const [loading, setLoading] = useState(false);
  const { activeLanguage } = useContext(LanguageContext);

  const emailInputRef = useRef(null);
  const codeInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const navigate = useNavigate();

  const [emailInput, setEmailInput] = useState("");
  const [codeInput, setCodeInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");

  const [email, setEmail] = useState(false);
  const [code, setCode] = useState(false);
  const [password, setPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const isFormEmailValid = emailInput !== "";
  const isFormCodeValid = codeInput !== "";
  const isFormPasswordValid = passwordInput !== "";

  const { showCustomToast } = useToast();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
    if (passwordInputRef.current) {
      passwordInputRef.current.type = showPassword ? "password" : "text";
    }
  };

  const handleEmailChange = (event) => {
    setEmailInput(event.target.value);
  };

  const handleCodeChange = (event) => {
    let inputValue = event.target.value.toUpperCase();
    inputValue = inputValue.replace(/[^a-zA-Z0-9]/g, "");

    if (inputValue.length > 3) {
      inputValue = inputValue.slice(0, 3) + "-" + inputValue.slice(3, 6);
    }
    if (inputValue.length > 7) {
      inputValue = inputValue.slice(0, 7);
    }

    setCodeInput(inputValue);
  };

  const handlePasswordChange = (event) => {
    setPasswordInput(event.target.value);
  };

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    const templateMap = {
      pt: "tp_password_pt",
      en: "tp_password_en",
      fr: "tp_password_fr",
    };

    try {
      const response = await createVerificationCode(emailInput);
      const firstName =
        response.clientName && response.clientName.split(" ")[0];
      const displayName = firstName || "Cliente 3ÄDY";
      const serviceID = "service_fh4ymj3";
      const templateID = templateMap[activeLanguage];
      const userID = "TMBTWmu57P_4Ks9Qn";

      await emailjs.send(
        serviceID,
        templateID,
        {
          to_name: displayName,
          from_name: "Thryad",
          recovery_code: response.clientCode,
          to_email: emailInput,
          reply_to: "contact@thryad.club",
        },
        userID
      );

      showCustomToast(
        languages[activeLanguage].verificationEmailSent,
        "success"
      );
      setCode(true);
      setEmail(false);
    } catch (error) {
      if (error.code === "EMAIL_NOT_FOUND") {
        showCustomToast(languages[activeLanguage].emailNotFound, "error");
      } else {
        showCustomToast(languages[activeLanguage].emailSendError, "error");
        console.error("Error sending email:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const code = await getVerificationCode(emailInput);

      if (codeInput.trim() === code) {
        showCustomToast(languages[activeLanguage].codeVerified, "success");
        setPassword(true);
        setCode(false);
        updateVerificationCode(emailInput);
      } else {
        showCustomToast(languages[activeLanguage].invalidCode, "error");
      }
    } catch (error) {
      if (error.code === "CODE_EXPIRED") {
        showCustomToast(languages[activeLanguage].codeExpired, "error");
      } else {
        showCustomToast(languages[activeLanguage].unexpectedError, "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const hashedPassword = await hashPassword(passwordInput);
      const updateDate = Timestamp.now();

      await updatePassword(emailInput, hashedPassword, updateDate);

      showCustomToast(languages[activeLanguage].passwordUpdated, "success");
      setLoading(false);

      navigate(routes.login);
    } catch (error) {
      showCustomToast(languages[activeLanguage].passwordUpdateError, "error");
      console.error("Error updating password:", error);
    }

    setLoading(false);
  };

  const hashPassword = async (password) => {
    try {
      const salt = await bcrypt.genSalt(saltRounds);
      const hashedPassword = await bcrypt.hash(password, salt);
      return hashedPassword;
    } catch (error) {
      console.error("Error generating password hash:", error);
      throw new Error("Failed to generate password hash");
    }
  };

  useEffect(() => {
    if (email) {
      if (emailInputRef.current) {
        emailInputRef.current.focus();
      }
    }
  }, [email]);

  useEffect(() => {
    if (code) {
      if (codeInputRef.current) {
        codeInputRef.current.focus();
      }
    }
  }, [code]);

  useEffect(() => {
    if (password) {
      if (passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
    }
  }, [password]);

  useEffect(() => {
    setEmail(true);
  }, []);

  return (
    <section className="ct-access">
      <div className="access">
        <form>
          <p className="label-links">
            {languages[activeLanguage].forgotPassword}
          </p>
          <h1>
            {languages[activeLanguage].changePassword}{" "}
            <span className="brand" onClick={() => navigate(routes.home)}>
              <strong>3ÄDY</strong>
            </span>
          </h1>
          {email && (
            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control"
                id="floatingInput"
                ref={emailInputRef}
                value={emailInput}
                onChange={handleEmailChange}
              />
              <label for="floatingInput">
                {languages[activeLanguage].enterYourEmail}
              </label>
            </div>
          )}
          {code && (
            <div class="form-floating mb-3">
              <input
                type="email"
                class="form-control"
                id="floatingInput"
                ref={codeInputRef}
                value={codeInput}
                onChange={handleCodeChange}
              />
              <label for="floatingInput">
                {languages[activeLanguage].provideCode}
              </label>
            </div>
          )}
          {password && (
            <div class="form-floating">
              <input
                type="password"
                class="form-control"
                id="floatingPassword"
                ref={passwordInputRef}
                value={passwordInput}
                onChange={handlePasswordChange}
              />
              <label for="floatingPassword">
                {languages[activeLanguage].createNewPassword}
              </label>
              <span className="eye" onClick={togglePasswordVisibility}>
                <img
                  loading="lazy"
                  alt="eye"
                  src={showPassword ? eyeSlash : eye}
                />
              </span>
            </div>
          )}
          {email && (
            <div>
              {loading ? (
                <button
                  type="button"
                  className="btn btn-outline-dark disable"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  {languages[activeLanguage].pleaseWait}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-dark"
                  onClick={handleVerifyEmail}
                  disabled={!isFormEmailValid}
                >
                  {languages[activeLanguage].verifyEmail}
                </button>
              )}
            </div>
          )}
          {code && (
            <div>
              {loading ? (
                <button
                  type="button"
                  className="btn btn-outline-dark disable"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  {languages[activeLanguage].pleaseWait}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-dark"
                  onClick={handleVerifyCode}
                  disabled={!isFormCodeValid}
                >
                  {languages[activeLanguage].verifyCode}
                </button>
              )}
            </div>
          )}
          {password && (
            <div>
              {loading ? (
                <button
                  type="button"
                  className="btn btn-outline-dark disable"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  {languages[activeLanguage].pleaseWait}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-outline-dark"
                  onClick={handleChangePassword}
                  disabled={!isFormPasswordValid}
                >
                  {languages[activeLanguage].saveNewPassword}
                </button>
              )}
            </div>
          )}
          <p className="label-links support">
            {languages[activeLanguage].contactSupport}{" "}
            <span
              onClick={() =>
                window.open("https://wa.me/5548991586445", "_blank")
              }
              className="links"
            >
              +55 48 99158-6445
            </span>
          </p>
        </form>
      </div>
    </section>
  );
}

export default Password;
